<template>
  <button :class="['btn flex-center lt-sm:fs-12!', type, (disabled || loading) ? 'disabled' : '', sizeClass, rounded ? 'is-circle' : '' ]" :disabled="disabled">
    <SvgIcon v-if="loading" name="loading" class="mr-4px w-20px h-20px"></SvgIcon>
    <div class="flex-center"><slot></slot></div>
  </button>
</template>

<script setup lang="ts">
	import SvgIcon from './SvgIcon.vue';

	const props = defineProps({
		type: {
			type: String as PropType<'default' | 'cancel' | 'info'>,
			default: 'default'
		},
		disabled: {
			type: Boolean,
			default: false
		},
		size: {
			type: String as PropType<'small' | 'middle' | 'large'>,
			default: 'middle'
		},
		loading: {
			type: Boolean,
			default: false
		},
		rounded: {
			type: Boolean,
			default: false
		}
	});

	const sizeClass = computed(() => {
		return `btn__${props.size || 'middle'}`;
	});
</script>

<style lang="less" scoped>
.btn {
  min-width: 32px;
  cursor: pointer;
  text-align: center;
  &.is-circle {
    border-radius: 50%;
  }
  &.btn__middle {
    height: 40px;
    line-height: 40px;
    font-size: 14px;
    padding: 0 16px;
    font-weight: 500;
    border-radius: 12px;
    &.is-circle {
      width: 40px;
      height: 44px;
      padding: 8px;
    }
  }
  &.btn__small {
    height: 32px;
    line-height: 32px;
    font-size: 14px;
    padding: 0 12px;
    border-radius: 12px;
    &.is-circle {
      width: 32px;
      height: 32px;
      padding: 6px;
    }
  }
  &.btn__large {
    height: 52px;
    line-height: 52px;
    font-size: 18px;
    padding: 0 20px;
    border-radius: 16px;
    font-weight: 600;
    &.is-circle {
      width: 52px;
      height: 52px;
      padding: 10px;
    }
  }
  &.disabled {
    @apply opacity-50 cursor-not-allowed;
  }
  &.cancel {
    @apply bg-deep-30 c-deep-70;
    &:not(.disabled):hover {
      opacity: 80%;
    }
    &.disabled {
      background: rgba(210, 213, 227, 0.4);
    }
  }
  &.info {
    @apply bg-pr-20 c-pr-90;
    &:not(.disabled):hover {
      opacity: 80%;
    }
    &.disabled {
      @apply bg-pr-20 bg-opacity-40;
    }
  }
  &.default {
    @apply bg-primary c-deep-0;
    &:not(.disabled):hover {
      opacity: 80%;
    }
    &.disabled {
      background: rgba(97, 101, 246, 0.4);
    }
  }
}
</style>
